<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="货源编号" prop="pno">
            <el-input v-model="conditions.pno" clearable> </el-input>
          </el-form-item>
          <el-form-item label="运单编号" prop="transportCode">
            <el-input v-model="conditions.transportCode" clearable> </el-input>
          </el-form-item>
          <el-form-item label="发货公司" prop="sendOwnerId">
            <el-select v-model="conditions.sendOwnerId" clearable filterable>
              <el-option
                :key="item.id"
                v-for="item in ownerOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收货公司" prop="receiveOwnerId">
            <el-select v-model="conditions.receiveOwnerId" clearable filterable>
              <el-option
                :key="item.id"
                v-for="item in ownerOptions"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="装货日期" prop="loaddate">
            <el-date-picker
              v-model="conditions.loaddate"
              type="date"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="车牌号" prop="plate">
            <el-input v-model="conditions.plate" clearable> </el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="conditions.status" clearable>
              <el-option
                v-for="(item, index) in enumStatus"
                :key="index"
                :value="index"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="运单来源" prop="dispatchFrom">
            <el-select v-model="conditions.dispatchFrom" clearable>
              <el-option
                v-for="(item, index) in fromEnum"
                :key="index"
                :value="index"
                :label="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-table border :data="tableList" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="运单编号"
            prop="transportCode"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="货源编号"
            prop="pno"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="发货公司"
            prop="sendOwner"
          >
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="收货公司"
            prop="receiveOwner"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="车牌号" prop="plate">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="司机姓名"
            prop="driverName"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="车队" prop="fleetName">
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="装货时间"
            prop="loaddate"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="来源">
            <template slot-scope="scope">
              {{ fromEnum[scope.row.dispatchFrom] }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="状态">
            <template slot-scope="scope">
              <el-tag
                size="small"
                :type="enumStatus[scope.row.status].type"
                v-if="enumStatus[scope.row.status]"
                >{{ enumStatus[scope.row.status].label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="goDetails(scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import { mapState } from "vuex";
// import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      listitem: [{ name: "计划管理" }, { name: "计划列表" }],
      conditions: {
        page: 1,
        limit: 10,
        transportCode: "",
        sendOwnerId: "",
        receiveOwnerId: "",
        status: "10",
        pno: "",
        plate: "",
        dispatchFrom: "",
      },
      enumStatus: {
        10: { label: "待审核", type: "warning" },
        11: { label: "未通过", type: "info" },
        20: { label: "待起运" },
        21: { label: "待送达" },
        22: { label: "已完工", type: "success" },
      },
      fromEnum: {
        1: "货主",
        2: "车队",
        3: "抢单",
      },
      ownerOptions: [],
      tableList: [],
      total: 0,
    };
  },
  // computed: {
  //   ...mapState(["conditionsList"]),
  // },
  components: {
    Pagination,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    this.getOwnerOptions();
    this.getList();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getPendingCount();
    });
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    // ...mapMutations(["createConditions"]),
    async getOwnerOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/list",
        "get",
        {}
      );
      this.ownerOptions = data.data.list;
    },
    // 获取列表数据
    async getList(num) {
      const { data } = await this.$http(
        "api1",
        "/api/biz/TransportBill/auditList",
        "get",
        this.conditions
      );
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
    },
    // 获取货场列表
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    goDetails(row) {
      this.$router.push({
        path: "/check/transportBillDetails",
        query: { id: row.planId, transportBillId: row.id },
      });
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
